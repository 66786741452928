import React, { useEffect } from 'react';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import useContextBar from './useContextBar';
import css from './context-bar.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'utils/translation';
import Typography from '../Typography/Typography';
import { MIN_WIDTHS, useResizableContextBar } from './useResizableContextBar';

type Props = {
  disableClose?: boolean;
  title?: React.ReactNode;
  icon?: React.ReactNode;
  onClose?(): void;
  size?: 'default' | 'large';
};

const ContextBar = ({
  children,
  disableClose = false,
  onClose,
  size = 'default',
  ...props
}: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { reset, close, isOpen, options } = useContextBar();
  const {
    icon = props.icon,
    title = props.title || '',
    component: Component,
  } = options || {};
  const rootRef = React.useRef(null);
  const isActive = isOpen || children;
  const { isDragging, width, handle } = useResizableContextBar({
    minWidth: size === 'large' ? MIN_WIDTHS.LARGE : undefined,
    ref: rootRef,
  });
  const extraStyles = isActive ? { width: `${width}px` } : undefined;

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const handleClose = () => {
    onClose?.();
    close();
  };

  return (
    <div
      ref={rootRef}
      style={extraStyles}
      className={clsx(
        css.root,
        isActive && css['is-active'],
        isDragging && css['is-dragging'],
        size && css[`size-${size}`],
      )}
    >
      <div style={extraStyles} className={css.inner}>
        {handle}
        {(icon || title || !disableClose) && (
          <div className={css.header}>
            {icon && <div className={css.icon}>{icon}</div>}
            <Typography variant={'h2'} className={css.title}>
              {title}
            </Typography>
            <div className={css['close-button']}>
              {!disableClose && (
                <Button
                  variant="text"
                  startIcon={<Icon name="fa-regular fa-times" />}
                  onClick={handleClose}
                />
              )}
            </div>
          </div>
        )}
        <div className={css.content}>
          {Component ? <Component /> : children}
        </div>
      </div>
    </div>
  );
};

export default ContextBar;
