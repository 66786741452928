import React from 'react';
import { Menu, MenuItem, MenuButton, MenuButtonSize } from '../Menu/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base';
import Icon from '../Icon/Icon';
import { Dropdown } from '@mui/base';

export type Action = {
  label: string | React.ReactElement;
  disabled?: boolean;
  divider?: boolean;
  visible?: boolean;
  action?(): void;
  icon?: React.ReactElement | null;
};

type Props = {
  actions: Action[];
  button?: React.ComponentType;
  icon?: React.ReactElement;
  size?: MenuButtonSize;
};

const ActionSheet: React.FC<Props> = (props) => {
  const {
    actions,
    button = null,
    icon = <Icon name={'far fa-ellipsis-vertical'} />,
    size,
  } = props;

  const items = actions.filter(({ visible = true }) => visible);

  return (
    <>
      {button ? (
        <BaseMenuButton slots={{ root: button }} />
      ) : (
        <MenuButton size={size} startIcon={icon} />
      )}
      <Menu data-testid="menu">
        {items.map(({ label, divider, disabled, action, icon }, index) => (
          <MenuItem
            key={index}
            divider={divider}
            disabled={disabled}
            onClick={action}
            icon={icon}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default (props: Props) => (
  <Dropdown>
    <ActionSheet {...props} />
  </Dropdown>
);
