import http from 'utils/http';
import fileDownload from 'utils/file-download';
import { File, MoveFilePayload, QueuedFile, UploadedFile } from '../index';
import { v4 as uuid } from 'uuid';
import axios from 'axios';

const CHUNK_SIZE = 1024 * 1000 * 10; // 10MB

function noop() {}

type UploadResultsPair = [Promise<File>, () => void];

const abortBridge: {
  [key: string]: () => void;
} = {};

const registerAbortCallback = (
  file: Pick<QueuedFile, 'id'>,
  callback: () => void,
) => {
  abortBridge[file.id] = callback;
};

export const abortUpload = (file: Pick<QueuedFile, 'id'>) => {
  const abortCallback = abortBridge[file.id];

  if (abortCallback) {
    abortCallback();
    delete abortBridge[file.id];
  }
};

function slice(file: any, start: number, end: number) {
  const slice = file.mozSlice
    ? file.mozSlice
    : file.webkitSlice
      ? file.webkitSlice
      : file.slice
        ? file.slice
        : noop;

  return slice.bind(file)(start, end);
}

async function uploadChunk({
  chunk,
  chunkSize,
  chunkNumber,
  dirId,
  name,
  totalSize,
  uuid,
  onUploadProgress,
}: any): Promise<File> {
  const formData = new FormData();
  formData.append('file', chunk);
  formData.append('name', name);
  formData.append('uuid', uuid);
  formData.append('_chunkSize', chunkSize);
  formData.append('_currentChunkSize', chunk.size);
  formData.append('_totalSize', totalSize);
  formData.append('_chunkNumber', chunkNumber);

  if (dirId) {
    formData.append('dir_id', dirId);
  }

  return http.post('api/file/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
}

function uploadChunked({
  file,
  dirId,
  onUploadProgress,
}: any): UploadResultsPair {
  const size = file.size;
  const name = file.name;
  const uid = uuid();
  const chunkSize = CHUNK_SIZE;
  let start = 0;
  let chunkNumber = 0;
  let aborted = false;

  async function loop(): Promise<File> {
    let end = start + chunkSize;

    if (size - end < 0) {
      end = size;
    }

    const chunk = slice(file, start, end);
    const startProgress = Math.round((start / size) * 100) / 100;
    const endProgress = Math.round((end / size) * 100) / 100;
    const deltaProgress = endProgress - startProgress;

    const handleChunkProgress = (event: any) => {
      if (event.lengthComputable) {
        const chunkProgress =
          Math.round((event.loaded / event.total) * 100) / 100;
        const realProgress = startProgress + deltaProgress * chunkProgress;
        onUploadProgress({ progress: Math.round(realProgress * 100) / 100 });
      }
    };

    const response = await uploadChunk({
      chunk,
      dirId,
      uuid: uid,
      name,
      chunkSize,
      chunkNumber,
      totalSize: size,
      onUploadProgress: handleChunkProgress,
    });
    chunkNumber++;

    if (aborted) {
      await abortChunkedUpload({ id: uid });
      throw new Error('Upload has been aborted.');
    }

    if (end < size) {
      start = end;
      onUploadProgress({ progress: endProgress });
      return loop();
    } else {
      return response;
    }
  }

  const abort = () => {
    aborted = true;
  };

  registerAbortCallback(file, abort);

  return [loop(), abort];
}

function uploadSingle({
  file,
  dirId,
  onUploadProgress,
}: any): UploadResultsPair {
  const source = axios.CancelToken.source();

  const onProgress = (event: any) => {
    if (event.lengthComputable) {
      const progress = Math.round((event.loaded / event.total) * 100) / 100;
      onUploadProgress({ progress });
    }
  };

  const formData = new FormData();
  formData.append('file', file);

  if (dirId) {
    formData.append('dir_id', dirId);
  }

  const promise = http.post<never, File>('api/file/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: onProgress,
    cancelToken: source.token,
  });

  const abort = () => source.cancel('Upload canceled');
  registerAbortCallback(file, abort);

  return [promise, abort];
}

export function upload({ file, dirId, onUploadProgress, cancelToken }: any) {
  if (file.size > CHUNK_SIZE) {
    return uploadChunked({ file, dirId, onUploadProgress });
  }

  return uploadSingle({
    file,
    dirId,
    onUploadProgress,
    cancelToken,
  });
}

function abortChunkedUpload(file: Pick<UploadedFile, 'id'>) {
  return http.delete(`api/abort-upload/${file.id}`);
}

export async function download(file: File) {
  const response: any = await http.get(`api/file/download/${file.id}`);

  if (response.url) {
    await fileDownload({
      url: response.url,
    });
  }
}

export function fetch() {
  return http.get<never, { data: File[] }>('api/file/');
}

export function read(file: Pick<File, 'id'>) {
  return http.get<never, File>(`api/file/${file.id}`);
}

export function getThumb(file: File) {
  return http.get(`api/file/thumb/${file.id}`);
}

export function getPreview(file: File) {
  return http.get(`api/file/preview/${file.id}`);
}

export function updateFile(file: File) {
  return http.put(`api/file/${file.id}`, file);
}

export function moveFile({ file, dirId }: MoveFilePayload) {
  return http.put(`api/file/move/${file.id}`, {
    directory_id: dirId > 0 ? dirId : null,
  });
}

export function deleteFile(file: File) {
  return http.delete(`api/file/${file.id}`);
}

export function shareFile(file: File) {
  return http.post<never, { url: string }>(`api/share/file/${file.id}`);
}
