/* eslint-disable quotes */
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Field, FieldUpdatePayload } from '..';
import FluentForm from 'components/v2/Form/FluentForm';
import Form from 'components/v2/Form/Form';
import { useQuery } from '@tanstack/react-query';
import { Button, ButtonGroup } from '../../../components/v2/Button/Button';
import { FieldType } from '../../../components/v2/Form/types';
import { useTranslation } from 'utils/translation';
import Info from '../../../components/v2/Info/Info';
import { Dir } from '../../dirs';
import { patchUpdateDir, readDir } from '../../dirs/service/api';
import { mapDirectoryTranslatedTitle } from '../../dirs/utils';
import ConditionalFieldCheckerModern from '../components/ConditionalFieldCheckerModern';
import {
  fetchIntelligentFields,
  updateIntelligentFields,
} from '../service/api';
import Loader from 'components/v2/Loader';
import Section from 'components/v2/Section/Section';
import Spacer from 'components/v2/Spacer/Spacer';
import { groupBy } from 'lodash';
import { useAppSettings } from '../../ui/hooks';
import Tabs from '../../../components/v2/Tabs/Tabs';
import Typography from '../../../components/v2/Typography/Typography';
import {
  mapFieldToProps,
  mapPayloadToValues,
  mapValuesToPayload,
} from '../utils';
import { useModalContent } from 'components/v2/Modal/useModalContent';
import { useAppFeatures } from '../../ui/selectors/app';
import { File } from '../../files';
import {
  useFileIntelligenceFields,
  useUpdateFileIntelligenceFields,
} from '../hooks';
import QueryResult from '../../../components/v2/Query/QueryResult';

export type Props = {
  identifier: string;
  enableTitle?: boolean;
  resolver(): Promise<[any, Field[]]>;
  persister(values: FieldUpdatePayload & { title?: any }): Promise<void>;
  onSuccess?(): void;
  onError?(): void;
};

const SingleField = ({ field }: { field: Field }) => {
  const { getValues } = useFormContext();
  const values = mapValuesToPayload(getValues());

  return (
    <ConditionalFieldCheckerModern
      condition={field.settings?.condition}
      values={values}
    >
      <Form.Field {...mapFieldToProps(field)} />
    </ConditionalFieldCheckerModern>
  );
};

export const BasicFieldsForm = ({
  fields,
  persister,
  onSuccess,
  onError,
  defaultValues,
  enableTitle = false,
  ...rest
}: {
  fields: Field[];
} & Pick<Props, 'persister' | 'onSuccess' | 'onError' | 'enableTitle'> &
  Pick<
    React.ComponentProps<typeof Form>,
    'defaultValues' | 'onChange' | 'errors'
  >) => {
  const {
    settings: { locales },
  } = useAppSettings();
  const { t } = useTranslation();
  const groups = groupBy(fields, (item) => item.group?.name.toLowerCase());
  const tabs = Object.keys(groups);
  const localeTabs = locales.filter((item: string) => tabs.includes(item));

  if (fields.length === 0) {
    return (
      <Section>
        <Info type="info" message={t('no_data_found')} />
        <Spacer size={2} />
        <ButtonGroup align="right">
          <Button onClick={onSuccess}>{t('Close')}</Button>
        </ButtonGroup>
      </Section>
    );
  }

  return (
    <Section>
      <FluentForm
        {...rest}
        action={persister}
        onSuccess={onSuccess}
        onError={onError}
        defaultValues={{
          ...defaultValues,
          ...mapPayloadToValues(fields),
        }}
      >
        {tabs.length > 1 ? (
          <Tabs fullWidth>
            <Tabs.List>
              {localeTabs.map((locale: string) => (
                <Tabs.Tab key={locale}>{locale}</Tabs.Tab>
              ))}
            </Tabs.List>
            {localeTabs.map((locale: string) => (
              <Tabs.Panel key={locale}>
                {enableTitle && (
                  <Form.Field
                    rules={{ required: true }}
                    name={`title.${locale}`}
                    type={FieldType.Text}
                    label={t('Title')}
                  />
                )}
                {groups[locale]!.map((item) => (
                  <React.Fragment key={item.id}>
                    {item.settings?.title && (
                      <Typography variant="h2">
                        {item.settings.title}
                      </Typography>
                    )}
                    <SingleField field={item} />
                  </React.Fragment>
                ))}
              </Tabs.Panel>
            ))}
          </Tabs>
        ) : (
          <>
            {enableTitle && (
              <Form.Field
                rules={{ required: true }}
                name="title"
                type={FieldType.Text}
                label={t('Title')}
              />
            )}
            {fields.map((item) => (
              <React.Fragment key={item.id}>
                {item.settings?.title && (
                  <Typography variant="h2">{item.settings.title}</Typography>
                )}
                <SingleField field={item} />
              </React.Fragment>
            ))}
          </>
        )}
      </FluentForm>
    </Section>
  );
};

const IntelligenceFieldsForm = ({
  identifier,
  resolver,
  persister,
  onSuccess,
  onError,
  enableTitle = false,
}: Props) => {
  const { translatableDirectories } = useAppFeatures();
  const { data = [], isLoading } = useQuery({
    queryFn: resolver,
    queryKey: [identifier],
    gcTime: 0,
    retry: false,
  });

  if (isLoading) {
    return (
      <Section>
        <Spacer />
        <Loader />
        <Spacer />
      </Section>
    );
  }

  const [title = translatableDirectories ? {} : '', fields = []] = data;

  return (
    <BasicFieldsForm
      enableTitle={enableTitle}
      defaultValues={{
        title,
      }}
      fields={fields}
      persister={persister}
      onSuccess={onSuccess}
      onError={onError}
    />
  );
};

export const DirectoryIntelligenceFieldsForm = ({
  id,
  onSuccess,
  enableTitle = false,
}: Pick<Dir, 'id'> & Pick<Props, 'onSuccess' | 'enableTitle'>) => {
  const {
    settings: { locales },
  } = useAppSettings();
  const modal = useModalContent();
  const { translatableDirectories } = useAppFeatures();

  const onError = () => {
    modal.scrollToTop();
  };

  return (
    <IntelligenceFieldsForm
      enableTitle={enableTitle}
      identifier={`intelligence_values_of_directory_${id}`}
      resolver={() =>
        Promise.all([
          readDir({ id }).then((dir) =>
            translatableDirectories
              ? mapDirectoryTranslatedTitle(dir, locales)
              : dir.title,
          ),
          fetchIntelligentFields({ id }),
        ])
      }
      persister={async ({ title, ...values }) => {
        if (enableTitle) {
          await patchUpdateDir({ id, title });
        }

        await updateIntelligentFields({
          dirId: id,
          fields: mapValuesToPayload(values),
        });
      }}
      onSuccess={onSuccess}
      onError={onError}
    />
  );
};

export const FileIntelligenceFieldsForm = ({
  id,
  onSuccess,
}: Pick<File, 'id'> & Pick<Props, 'onSuccess'>) => {
  const modal = useModalContent();
  const query = useFileIntelligenceFields({ id });
  const mutation = useUpdateFileIntelligenceFields({ id });

  const onError = () => {
    modal.scrollToTop();
  };

  return (
    <QueryResult
      {...query}
      render={(data) => (
        <BasicFieldsForm
          fields={data}
          persister={(values) => mutation.mutateAsync(values)}
          onSuccess={onSuccess}
          onError={onError}
        />
      )}
    />
  );
};
