import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer';

import './assets/scss/index.scss';

import './mixins/moment';
import './mixins/dayjs';
import './mixins/validate';
import './utils/translation';

import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://488c3c5fc139478b8fd4db974a10e4ed@sentry.io/1833241',
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

const renderApp = (Application: React.ComponentType) =>
  root.render(<Application />);

renderApp(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
