import React, { forwardRef, HTMLProps, PropsWithChildren } from 'react';
import css from './table.module.scss';
import clsx from 'clsx';
import Icon from '../Icon/Icon';

const TableWrapper: React.FC<HTMLProps<HTMLDivElement>> = (props) => (
  <div {...props} className={css.wrapper} />
);

const TableActions: React.FC<HTMLProps<HTMLDivElement>> = (props) => (
  <div {...props} className={css.actions} />
);

const Table: React.FC<HTMLProps<HTMLTableElement> & { isFixed?: boolean }> = ({
  isFixed = false,
  ...rest
}) => <table {...rest} className={clsx(css.table, isFixed && css.fixed)} />;

const TableRow: React.FC<
  HTMLProps<HTMLTableRowElement> & {
    isSelectable?: boolean;
    isSelected?: boolean;
  }
> = ({ isSelected = false, isSelectable = false, ...props }) => (
  <tr
    {...props}
    className={clsx(
      css.row,
      isSelected && css['is-selected'],
      isSelectable && css['is-selectable'],
    )}
  />
);

const TableHead: React.FC<HTMLProps<HTMLTableSectionElement>> = (props) => (
  <thead {...props} className={css.head} />
);

const TableBody: React.FC<
  HTMLProps<HTMLTableSectionElement> & { hover?: boolean }
> = ({ hover = true, ...props }) => (
  <tbody {...props} className={clsx(css.body, hover && css['has-hover'])} />
);

const TableFooter: React.FC<HTMLProps<HTMLTableSectionElement>> = (props) => (
  <tfoot {...props} className={css.footer} />
);

const TableCell = ({
  variant = 'cell',
  size,
  children,
  align,
  ...props
}: PropsWithChildren<{
  variant?: 'cell' | 'head';
  size?: 'small';
  align?: 'left' | 'right' | 'center';
  onClick?(event: React.MouseEvent): void;
}> &
  Pick<React.HTMLProps<HTMLTableCellElement>, 'colSpan'>) => {
  const Tag = variant === 'cell' ? 'td' : 'th';

  return (
    <Tag
      {...props}
      className={clsx(
        css.cell,
        size && css[size],
        align && css[`align-${align}`],
        props.onClick && 'clickable',
      )}
    >
      {children}
    </Tag>
  );
};

const TableCellContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  ({ children, ...props }, ref) => {
    return (
      <div className={css['cell-content']} {...props} ref={ref}>
        {children}
      </div>
    );
  },
);

const SortAsc = ({
  children,
  ...rest
}: React.PropsWithChildren<HTMLProps<HTMLSpanElement>>) => (
  <span {...rest} className={css.sort}>
    {children}
    <Icon className={css['sort-icon']} name="fas fa-arrow-down" />
  </span>
);

const SortDesc = ({
  children,
  ...rest
}: React.PropsWithChildren<HTMLProps<HTMLSpanElement>>) => (
  <span {...rest} className={css.sort}>
    {children}
    <Icon className={css['sort-icon']} name="fas fa-arrow-up" />
  </span>
);

const Sortable = ({
  children,
  ...rest
}: React.PropsWithChildren<HTMLProps<HTMLSpanElement>>) => (
  <span {...rest} className={css.sort}>
    {children}
  </span>
);

export default Object.assign(Table, {
  Wrapper: TableWrapper,
  Actions: TableActions,
  Row: TableRow,
  Head: TableHead,
  Body: TableBody,
  Foot: TableFooter,
  Cell: TableCell,
  CellContent: TableCellContent,
  SortAsc,
  SortDesc,
  Sortable,
});
