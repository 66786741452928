import React from 'react';
import { FormField as FieldType } from './types';
import registry from './registry';

const Field: React.FC<FieldType> = (props) => {
  const { name, type, label, rules, ...rest } = props;
  const Component = registry[type] as any;

  if (!Component) {
    throw new Error(`Unsupported element type: ${type}`);
  }

  return <Component {...rest} name={name} label={label} rules={rules} />;
};

export default Field;
